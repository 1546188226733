/* global tw */
import React, { Component } from "react";
import { graphql, Link } from "gatsby";
import { relatedArtist } from "~/queries/artistQuery"; // eslint-disable-line
import Layout from "../components/layout";
import PageWrapper from "../components/Structure/PageWrapper";
import Heading from "../components/Heading";
import CardSelections from "../components/CardSelections";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import SEO from "../components/seo";
import styled from "styled-components";

const TabsWrapper = styled.div`
	.react-tabs {
		-webkit-tap-highlight-color: transparent;
	}

	.react-tabs__tab-list {
		${tw`font-dincond uppercase font-black text-lg -mx-2 mb-3 p-0`};
	}

	.react-tabs__tab {
		${tw`inline-block mx-2 relative font-dincond uppercase leading-mini text-lg py-2`};
		list-style: none;
		cursor: pointer;
		border-bottom: 4px solid;
	}

	.react-tabs__tab--selected {
		${tw`text-red`};
	}

	.react-tabs__tab--disabled {
		${tw`border border-grey-light border-dashed text-grey cursor-pointer`};
	}

	.react-tabs__tab:focus {
		${tw`border border-grey-light border-dashed`};
		box-shadow: none;
		outline: none;
	}

	.react-tabs__tab-panel {
		${tw`hidden`};
	}

	.react-tabs__tab-panel--selected {
		${tw`block`};
	}
`;

class ArtistPost extends Component {
	render() {
		const {
			data: {
				recraft: { post },
				recraft: { related },
				recraft: { bonus },
				recraft: { extra }
			}
		} = this.props;

		return (
			<Layout location={this.props.location}>
				<SEO
					title={post.title}
					keywords={[`dumbo`, `places`, `dumbo businesses`]}
				/>
				<PageWrapper
					imageObject={
						!post.featuredImage || !post.featuredImage.length
							? null
							: post.featuredImage[0]
					}
					mainContent={
						<div>
							<Link
								to="/artists"
								className="uppercase text-xs tracking-wide text-grey-dark mb-4 block"
							>
								/ &ensp; Artists
							</Link>
							<Heading>{post.title}</Heading>
						</div>
					}
					body={post.body ? post.body.content : null}
					bonusContent={
						bonus.length ? (
							<TabsWrapper>
								<Tabs>
									<TabList>
										<Tab>Other Articles</Tab>
										<Tab>Other Places</Tab>
									</TabList>

									<TabPanel>
										<CardSelections post={post} items={bonus} />
									</TabPanel>
									<TabPanel>
										<CardSelections post={post} items={extra} />
									</TabPanel>
								</Tabs>
							</TabsWrapper>
						) : null
					}
					sidebarContent={<h2>Sidebar Content goes here.</h2>}
				/>

				{related.length ? (
					<CardSelections
						cardlistTitle={"Articles about " + post.title}
						post={post}
						items={related}
					/>
				) : null}
			</Layout>
		);
	}
}

export default ArtistPost;

export const pageQuery = graphql`
	query ArtistPostByUri($uri: String!, $id: [Int]) {
		recraft {
			post: entry(uri: $uri) {
				title
				...relatedArtist
			}
			related: entries(relatedTo: [{ element: $id }], section: news) {
				title
				... on Recraft_News {
					title
					id
					uri
					featuredImage {
						url
						focalPoint {
							x
							y
						}
					}
				}
			}
			bonus: entries(section: news, limit: 3) {
				title
				... on Recraft_News {
					title
					id
					uri
					featuredImage {
						url
						focalPoint {
							x
							y
						}
					}
				}
			}
			extra: entries(section: listings, limit: 3) {
				title
				... on Recraft_Listings {
					title
					id
					uri
					featuredImage {
						url
						focalPoint {
							x
							y
						}
					}
				}
			}
		}
	}
`;
